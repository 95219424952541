import { QUESTS_CONFIG, QUESTS_DIRECTORY } from "../components/quests/quests.consts";
import { QuestConfig, QuestGroup, QuestsProgress } from "../components/quests/quests.types";
import { UserQuests } from "./client";

export const dtoToQuests = (dto: UserQuests): QuestsProgress => {
  const quests = dto.completedQuests;
  const config: Partial<QuestsProgress> = {};

  Object.keys(QUESTS_DIRECTORY).forEach((groupKey) => {
    config[groupKey] = { quests: {} };
    QUESTS_DIRECTORY[groupKey].forEach((questKey) => {
      const complete = !!quests.find((q) => q === questKey);
      const qCfg: QuestConfig<QuestGroup> = QUESTS_CONFIG[groupKey].quests.find((q) => q.id === questKey);
      const steps = qCfg.steps.map((s) => s.id);
      config[groupKey].quests[questKey] = { complete, steps };
    });
  });

  return config as QuestsProgress;
};
