import emojiRegex from "emoji-regex";
import { mapTimes } from "./loop";

const emojiFindingRegex: RegExp = emojiRegex();

export const stringIf = (truthy: unknown, str: string, notStr = ""): string => (!!truthy ? str : notStr);

/**
 * Returns a string with `char` repeated `times` times
 * @param times the times of the output string
 * @param char the char (or string) to repeat
 * @returns a string of `char` repeated `times` times
 */
export const repeatChar = (times: number, char: string) => mapTimes(times, () => char).join("");

/**
 * Adds `char` to the left side of `str` until it is at least `minSize` long.
 * @param str The string to pad
 * @param minSize The min size of the output
 * @param char The character to pad with
 * @returns A left-padded string
 */
export const leftPadTo = (str: string, minSize: number, char = " ") => repeatChar(minSize - str.length, char) + str;

/**
 * Adds `char` to the right side of `str` until it is at least `minSize` long.
 * @param str The string to pad
 * @param minSize The min size of the output
 * @param char The character to pad with
 * @returns A right-padded string
 */
export const rightPadTo = (str: string, minSize: number, char = " ") => str + repeatChar(minSize - str.length, char);

export function makeMailToUrl(emails: string | string[], contents: { subject?: string; body?: string } = {}): string {
  emails = Array.isArray(emails) ? emails : [emails];

  return `mailto:${emails.join(",")}?${Object.keys(contents)
    .map((key) => `${key}=${encodeURIComponent(contents[key])}`)
    .join("&")}`;
}

export function isEmailish(email?: string) {
  return !email || /^[^@]+?(@([^@]+?(\.?[^@]+)?)?)?$/.test(email);
}

export function isEmail(email?: string | null) {
  if (!email) return false;
  return /.+\@(.{2,}\.)+.+/.test(email);
}

export function isWorkEmail(email?: string | null) {
  if (!email) return false;
  return !/@(gmail|googlemail|outlook|icloud|mac|live|hotmail|yahoo|fastmail)\.com$/i.test(email);
}

export function ucfirst(str?: string) {
  if (!str) return "";
  return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
}

/**
 * Changes snake case to words, replacing _ with space.
 * @param str The snake cased string to change to words
 * @param letterCase How to handle case change.  If blank case will remain unchanged else:
 * - `upper`: change all letters to upper-case
 * - `lower`: change all letters to lower-case
 * - `cap`: capitalize the first letter of each word
 * @returns A string with the words from the snake-cased string
 */
export function snakeToWords(str: string, letterCase?: "upper" | "lower" | "cap") {
  let words = str.split("_");

  switch (letterCase) {
    case "cap":
      words = words.map(ucfirst);
      break;
    case "lower":
      words = words.map((s) => s.toLowerCase());
      break;
    case "upper":
      words = words.map((s) => s.toUpperCase());
      break;
  }

  return words.join(" ");
}

export function titlecase(str: string, joiner: string = "") {
  if (!str) return "";
  return str
    .split(/[^\w\d]+/)
    .map(ucfirst)
    .join(joiner);
}

export function camelcase(str: string) {
  if (!str) return "";
  return str.toLowerCase().replace(/[^a-zA-Z0-9]+(.)/g, (m, ch) => ch.toUpperCase());
}

/**
 * Tests if a string is a URL.  Will NOT test relative URLs.
 * @param str
 * @returns
 */
export const isUrl = (str: string): boolean => {
  try {
    const url = new URL(str);
    // Don't let weird protocols through.

    /**
     ***** USE EXTREME CAUTION CHANGING THIS LINE *****
     */

    // Allowing all protocols could allow scripting
    // attacks by making `javascript:` (or other
    // scripting protocols) clickable
    return url.protocol === "http:" || url.protocol === "https:";
  } catch {
    return false;
  }
};

export function kebabcase(str: string | undefined, allowTrailingDashes?: boolean) {
  if (!str) return "";
  if (undefined) return "";

  str = str
    .toLowerCase()
    .replace(/\s/g, "-") // replace space with dash
    .replace(/_/g, "-") // replace underscore with dash
    .replace(/[àáâãäå]/g, "a")
    .replace(/[ç]/g, "c")
    .replace(/[Ð]/g, "d")
    .replace(/[èéêë]/g, "e")
    .replace(/[ìíîï]/g, "i")
    .replace(/[ñ]/g, "n")
    .replace(/[òóôõöøð]/g, "o")
    .replace(/[š]/g, "s")
    .replace(/[ùúûü]/g, "u")
    .replace(/[ÿý]/g, "y")
    .replace(/[ž]/g, "z")
    .replace(/[æ]/g, "ae")
    .replace(/[^a-zA-Z0-9-]/g, "") // anything other than these replace with nothing
    .replace(/-+/g, "-") // any multiple occurences of - replace with single -
    .replace(/-/g, "-") // any multiple occurences of - replace with single - sdfsdafasdfsfas
    .replace(/^-+/g, ""); // if first character is - replace with nothing
  // next line disabled user input of dashes
  if (!allowTrailingDashes) {
    str = str.replace(/-+$/g, ""); // if last character is - replace with nothing
  }
  return str;
}

export function pluralize(count: number, root: string, plural: string = "s") {
  return `${root}${Math.abs(count) !== 1 ? plural : ""}`;
}

export function labelize(count: number, root: string, plural?: string) {
  return `${count} ${pluralize(count, root, plural)}`;
}

export function parseEmoji(text: string): { emoji?: string; textWithoutEmoji: string } {
  const emoji = new RegExp(emojiFindingRegex).exec(text);

  return {
    emoji: emoji && emoji.index === 0 ? emoji[0] : undefined,
    textWithoutEmoji: emoji && emoji.index === 0 ? text.replace(emoji[0], "").trimStart() : text,
  };
}

export function titleWithEmoji(emoji: string | null | undefined, text?: string): string {
  if (!emoji) return text || "";
  const foundEmoji = emojiFindingRegex.exec(text || "");

  if (foundEmoji && foundEmoji.index === 0) {
    return `${emoji}${text || ""}`.trimStart();
  }
  return `${emoji} ${text || ""}`.trimStart();
}

export function list(items: string[], conjunction = "and") {
  if (!conjunction || items.length < 2) return items.join(", ");
  if (items.length === 2) return items.join(` ${conjunction} `);
  return items
    .slice(0, -1)
    .concat(`${conjunction} ${items[items.length - 1]}`)
    .join(", ");
}

export function isJson(str: string) {
  if (typeof str !== "string") return false;
  try {
    const result = JSON.parse(str);
    const type = Object.prototype.toString.call(result);
    return ["[object Object]", "[object Array]"].includes(type);
  } catch (err) {
    return false;
  }
}

export class Parser<T> {
  static Json = new Parser<{ [key: string]: unknown }>(
    "json",
    (str) => isJson(str),
    (str) => JSON.parse(str)
  );

  static Array = new Parser<string[]>(
    "array",
    (str) => /(?:\[?([\w\d._\-+]+)[\s,]+?)\]?/g.test(str),
    (str) => {
      const match = str.match(/(?:([\w\d._\-+]+)\s?[,]?)/g);
      if (!match?.length) return undefined;
      return Array.from(match);
    }
  );

  static Boolean = new Parser<boolean>(
    "boolean",
    (str) => /^(true|false)$/i.test(str),
    (str) => {
      const match = str.match(/^(true|false)$/i);
      if (!match || !match.length) return undefined;
      if (match[0].toLowerCase() === "true") return true;
      if (match[0].toLowerCase() === "false") return false;
      return undefined;
    }
  );

  static Number = new Parser<number>(
    "number",
    (str) => /^(\d*\.?\d+)$/i.test(str),
    (str) => {
      const match = str.match(/^(\d*\.?\d+)$/i);
      if (!match?.length) return undefined;
      const num = Number(match[0]);
      return !isNaN(num) ? num : undefined;
    }
  );

  static Null = new Parser<number>(
    "null",
    (str) => /^null$/i.test(str),
    (str) => {
      const match = str.match(/^null$/i);
      return match?.length ? null : undefined;
    }
  );

  static Undefined = new Parser<number>(
    "undefined",
    (str) => /^undefined$/i.test(str),
    (str) => {
      const match = str.match(/^undefined$/i);
      return match?.length ? null : undefined;
    }
  );

  static parse(str: string) {
    const parser = [Parser.Json, Parser.Array, Parser.Number, Parser.Boolean, Parser.Null, Parser.Undefined].find((p) =>
      p.test(str)
    );
    return parser ? parser.parse(str) : str;
  }

  name: string;
  test: (str: string) => boolean;
  parse: (str: string) => T | null | undefined;

  constructor(name: string, test: (str: string) => boolean, parse: (str: string) => T | null | undefined) {
    this.name = name;
    this.test = test;
    this.parse = parse;
  }
}

export const getTemplateKeyRegex = (key: string) => new RegExp(`\\{\\s*${key}\\s*\\}`, "gi");

export default {
  isEmailish,
  isEmail,
  isWorkEmail,
  ucfirst,
  titlecase,
  pluralize,
  labelize,
  isJson,
  Parser,
};
