import { makeStyles } from "@material-ui/core";
import { ClassNameMap } from "@material-ui/core/styles/withStyles";
import clsx from "clsx";
import { useState, VFC } from "react";
import YouTube from "react-youtube";
import { useQuestsActions } from "../../hooks/atoms/useQuests";
import { useCallbackSafeRef } from "../../hooks/useCallbackSafeRef";
import { Override } from "../../types";
import { Loading } from "../Loading";
import { Modal, ModalProps } from "../modal/Modal";
import { DEFAULT_VIDEO_PLAYER_OPTIONS } from "./quests.consts";
import { QuestStep, QuestStepConfig } from "./quests.types";

const useStyles = makeStyles(
  (theme) => ({
    root: {
      position: "relative",
    },
    loader: {
      position: "absolute",
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      margin: "auto",
    },
    container: {
      position: "relative",
      width: "100%",
      height: 0,
      paddingBottom: "56.25%",
      overflow: "hidden",
    },
    iframe: {
      width: "100%",
      height: "100%",
      position: "absolute",
      top: 0,
      left: 0,
    },
  }),
  {
    classNamePrefix: "QuestVideoPlayerModal",
  }
);

export type QuestVideoPlayerModalJSSClassKey = keyof ReturnType<typeof useStyles>;

export type QuestVideoPlayerModalProps = Override<
  ModalProps,
  {
    classes?: Partial<ClassNameMap<QuestVideoPlayerModalJSSClassKey>>;
    className?: string;
    step: QuestStepConfig<QuestStep>;
    onClose?: () => void;
  }
>;

export const QuestVideoPlayerModal: VFC<QuestVideoPlayerModalProps> = ({
  className,
  classes: extClasses,
  step,
  onClose,
  ...rest
}) => {
  const classes = useStyles({
    classes: extClasses,
  });

  const { onStepComplete } = useQuestsActions();

  const [ready, setReady] = useState(false);

  const opts = step.videoOptions || DEFAULT_VIDEO_PLAYER_OPTIONS;

  const onReady = useCallbackSafeRef(() => {
    setReady(true);
  });

  const handleClose = useCallbackSafeRef(() => {
    onStepComplete(step.id);
    onClose?.();
  });

  return (
    <Modal className={clsx(classes.root, className)} {...rest} onClose={handleClose} fullWidth maxWidth="md">
      <YouTube
        className={classes.container}
        iframeClassName={classes.iframe}
        videoId={step.videoLink}
        onReady={onReady}
        onEnd={handleClose}
        opts={{ playerVars: opts }}
      />
      {!ready && <Loading className={classes.loader} />}
    </Modal>
  );
};
